/* Import Font AirPayOne */
@font-face {
  font-family: OrbitronBold;
  src: url(./assets//fonts/Orbitron-Bold.ttf);
}

@font-face {
  font-family: OrbitronMedium;
  src: url(./assets//fonts/Orbitron-Medium.ttf);
}

@font-face {
  font-family: OrbitronRegular;
  src: url(./assets//fonts/Orbitron-Regular.ttf);
}

@font-face {
  font-family: PlayBold;
  src: url(./assets//fonts/Play-Bold.ttf);
}

@font-face {
  font-family: PlayRegular;
  src: url(./assets//fonts/Play-Regular.ttf);
}

.blur::before {
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  width: contain;
  border-radius: 4px;
  padding: 0.3rem;
}

.blur {
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  width: contain;
  border-radius: 4px;
  padding: 0.3rem;
}

.title-red {
  background: linear-gradient(90.02deg, #c80e0e 18.25%, #ae1717 82.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.space {
  white-space: nowrap;
}

.btn-bsc-icon-loading {
  -webkit-animation: btnSpin 2s infinite linear;
  -moz-animation: btnSpin 2s infinite linear;
  -o-animation: btnSpin 2s infinite linear;
  -ms-animation: btnSpin 2s infinite linear;
  animation: btnSpin 2s infinite linear;
}

@-webkit-keyframes btnSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes btnSpin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes btnSpin {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes btnSpin {
  0% {
    -ms-transform: rotate(0deg);
  }

  100% {
    -ms-transform: rotate(360deg);
  }
}

@keyframes btnSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


body.hidden {
    overflow-y: hidden;
}
.overFlow {
  overflow: hidden;
}

.height-content {
  min-height: calc(100vh - 325px);
}
.cus-pt-global{
  min-height: calc(100vh - 330px);
}
.bg {
  padding: 140px 0 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.swap-bg {
  background-image: url(./assets/imgASC/swap/swap_bg.jpg);
}

.history-bg {
  background-image: url(./assets/imgASC/history/history_bg.jpg);
}

.dashboard-bg {
  background-image: url(./assets/imgASC/dashboard/dashboard_bg.jpg);
}

.staking-bg {
  background-image: url(./assets/imgASC/staking/staking_bg.jpg);
}

.withdraw-bg {
  background-image: url(./assets/imgASC/withdraw/withdraw_bg.jpg);
}

.affiliate-bg {
  background-image: url(./assets/imgASC/affiliate/affiliate_bg.jpg);
}

.brkxSu,
.hmnaXY {
  background: rgba(1, 16, 51, 0.75);
  backdrop-filter: blur(8px) !important;
}

.brkxSu .CcBQw,
.hmnaXY .MgcdM {
  opacity: unset;
  background-color: unset;
}

.custom-menu {
  width: 100%;
}

@media only screen and (max-width: 968px) {
  .custom-menu {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 743px) {
  .affiliate-bg {
    background-image: url(./assets/imgASC/affiliate/affiliate_bg_sp.jpg);
  }
  .dashboard-bg {
    background-image: url(./assets/imgASC/dashboard/dashboard_bg_sp.jpg);
  }
  .swap-bg {
    background-image: url(./assets/imgASC/swap/swap_bg_sp.jpg);
  }
  .withdraw-bg {
    background-image: url(./assets/imgASC/withdraw/withdraw_bg_sp.jpg);
  }
  .bg {
    padding: 32vw 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    background: transparent;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select,
  textarea,
  input {
    font-size: 16px;
  }
}